@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=Merriweather+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300&display=swap');
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.react-slidedown {
  transition-duration: .2s;
}

#react-burger-menu-btn {
  display: none;
}

.bm-burger-button {
  display: none;
}

.bm-item-list {
  padding-top: 100px;
}

div .dropdown {
  visibility: hidden;
  transition: visibility .5s, max-height .3s;
  max-height: 0;
  overflow: hidden;

  background-color: #8293E3;
  border-radius: 0 0rem 0.25rem 0.25rem;
  overflow-y: hidden;
}

div .open {
  visibility: visible;
  /* Set max-height to something bigger than the box could ever be */
  max-height: 100vh;
  overflow-y: hidden;
}